/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';
import mbpLogger from 'mbp-logger';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { getFeatureFlag, getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsBot, getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getCurrentPageType } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';

import MobileGraphqlHeader from './MobileGraphqlHeader';
import GenericSkeleton from '../GraphqlSkeletonComponents/GenericSkeleton';

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        color: theme.palette.grey?.['50'] || '#aeaeae',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        padding: '0px 8px',
        width: '100%',
        boxShadow: '0 3px 6px rgba(48,69,98,.16), 0 3px 6px rgba(48,69,98,.23)',
    },
    headerMenuSkeleton: {
        height: '42px',
        width: '30px',
        margin: '8px auto',
    },
    headerLogoSkeleton: {
        height: '42px',
        width: '174px',
        flex: 1,
        margin: '8px auto',
    },
    headerSigninMenuSkeleton: {
        height: '42px',
        width: '30px',
        margin: '8px auto',
    },
    headerCartMenuSkeleton: {
        height: '42px',
        width: '30px',
        margin: '8px auto',
    },
}));

const MobileHeaderContainer = ({ isEyeBrowShow }) => {
    const classes = useStyles();
    const ffWhichCheckoutVariation = useSelector(getFeatureFlag('which-checkout-variation'));
    const ffIsHideMobileHeaderOnScrollEnabled = useSelector(getFeatureFlag('is-hide-mobile-header-on-scroll-enabled'));
    const ffIsSearchUiInMobileMenuEnabled = useSelector(getFeatureFlag('is-search-ui-in-mobile-menu-enabled'));
    const ffIsSearchUiInMobileMenuABEnabled = useSelector(getFeatureFlag('is-search-ui-in-mobile-menu-ab-testing'));
    const brand = useSelector(getBrand);
    const presentationFamily = useSelector(getPresentationFamily);
    const deviceType = useSelector(getSSRDeviceType);
    const pageType = useSelector(getCurrentPageType);
    const isBot = useSelector(getIsBot);

    if (isBot) {
        return (
            <MobileGraphqlHeader
                isEyeBrowShow={isEyeBrowShow}
                brand={brand}
                presentationFamily={presentationFamily}
                ffWhichCheckoutVariation={ffWhichCheckoutVariation}
                ffIsHideMobileHeaderOnScrollEnabled={ffIsHideMobileHeaderOnScrollEnabled}
                isSearchUiInMobileMenuEnabled={ffIsSearchUiInMobileMenuEnabled}
                deviceType={deviceType}
            />
        );
    }

    const {
        error, data, loading, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'MobileHeaderLocation',
        interactionName: `${brand.domain} - Get Campaign - Mobile Search Ui In Menu`,
        featureFlag: ffIsSearchUiInMobileMenuABEnabled,
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: pageType },
    });

    if (loading || variables?.skip) {
        return (
            <div className={classes.headerWrapper}>
                <GenericSkeleton className={classes.headerMenuSkeleton} id="header_ab_testing_skeleton_menu" />
                <GenericSkeleton className={classes.headerLogoSkeleton} id="header_ab_testing_skeleton_logo" />
                <GenericSkeleton className={classes.headerCartMenuSkeleton} id="header_ab_testing_skeleton_cart" />
                <GenericSkeleton className={classes.headerSigninMenuSkeleton} id="header_ab_testing_skeleton_sign_in" />
            </div>
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'MobileHeaderContainer',
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return (
            <MobileGraphqlHeader
                isEyeBrowShow={isEyeBrowShow}
                brand={brand}
                presentationFamily={presentationFamily}
                ffWhichCheckoutVariation={ffWhichCheckoutVariation}
                ffIsHideMobileHeaderOnScrollEnabled={ffIsHideMobileHeaderOnScrollEnabled}
                isSearchUiInMobileMenuEnabled={ffIsSearchUiInMobileMenuEnabled}
                deviceType={deviceType}
            />
        );
    }

    const userGroup = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    const isSearchUiInMobileMenuEnabled = userGroup?.toLowerCase?.() === 'test';

    return (
        <MobileGraphqlHeader
            isEyeBrowShow={isEyeBrowShow}
            brand={brand}
            presentationFamily={presentationFamily}
            ffWhichCheckoutVariation={ffWhichCheckoutVariation}
            ffIsHideMobileHeaderOnScrollEnabled={ffIsHideMobileHeaderOnScrollEnabled}
            isSearchUiInMobileMenuEnabled={isSearchUiInMobileMenuEnabled}
            deviceType={deviceType}
        />
    );
};

MobileHeaderContainer.propTypes = {
    isEyeBrowShow: bool.isRequired,
};

export default MobileHeaderContainer;
